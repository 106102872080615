import { Component, OnInit } from '@angular/core';
import { VisitsService } from './../../services/visits/visits.service';
import { LocalizeParser } from 'localize-router';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit {
  visits: any = [];

  constructor(
    private visitsService: VisitsService,
    private _localizeParser: LocalizeParser
  ) {}

  ngOnInit() {
    this.visitsService.list(this._localizeParser.currentLang)
      .subscribe(
        (data)=> this.visits = data,
        (err)=> console.log(err)
      );
  }

}
