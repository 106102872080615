import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizeRouterModule, LocalizeParser, ManualParserLoader, LocalizeRouterSettings } from 'localize-router';
import { Location } from '@angular/common';
import { BsDropdownModule, CollapseModule, ModalModule } from 'ngx-bootstrap';
import { GalleryModule } from '@ngx-gallery/core';

import { environment } from '../environments/environment';
import { ROUTES } from './app-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { VisitsComponent } from './components/visits/visits.component';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { HighlightComponent } from './components/highlight/highlight.component';
import { VisitsService } from './services/visits/visits.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function LocalizeLoaderFactory(translate, location, settings) {
    return new ManualParserLoader(translate, location, settings, environment.locales);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TutorialComponent,
    VisitsComponent,
    IntroductionComponent,
    HighlightComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LocalizeRouterModule.forRoot(ROUTES, {
      parser: {
        provide: LocalizeParser,
        useFactory: LocalizeLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    }),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    GalleryModule.forRoot()
  ],
  providers: [
    VisitsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
