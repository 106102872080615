import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from "rxjs";
import 'rxjs/add/operator/map';

import { environment } from '../../../environments/environment';

@Injectable()
export class VisitsService {

  constructor(
    private _http:HttpClient,
    private translate: TranslateService,
  ){}

  list(locale: any): Observable<any> {
    const headers = new HttpHeaders()
                        .set('Content-Type', 'application/json; charset=utf-8')
                        .set('x-iw-api-key', environment.apiKey)
                        .set('Accept-language', locale);


    return this._http.get(`${environment.backend}/media/iw360/lastItems`, {headers});
  }

}
