import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService, LocalizeParser } from 'localize-router';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentLocale: string;
  locales: any = [];
  isCollapsed: boolean = true;

  constructor(
    private _localize: LocalizeRouterService,
    private _localizeParser: LocalizeParser,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang(this._localizeParser.currentLang);
  }

  ngOnInit() {
    this.currentLocale = this._localizeParser.currentLang
    this.locales = environment.locales;
  }

  changeLocal(locale:string):void {
    this._localize.changeLanguage(locale);
    this.currentLocale = locale;
  }

}
