import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Gallery, GalleryItem, IframeItem } from '@ngx-gallery/core';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  videos: GalleryItem[];

  constructor(
    private translate: TranslateService,
    private gallery: Gallery
  ) {}

  ngOnInit() {
    this.translate.get('tutorial.modal.videos').subscribe((videos: any) => {
      let videosArray = Object.values(videos);
      this.videos = videosArray.map((video: any) => {
        return new IframeItem(video.srcUrl, video.previewUrl);
      });
    });
  }

  showModal(videoTutorialModal, videoTutorialGallery) {
    videoTutorialModal.show();
    // Fix gallery first slide display
    setTimeout(function() {
      videoTutorialGallery.next();
      videoTutorialGallery.prev();
    }, 400);
  }
}
